
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import customers from "@/core/data/customers";
import { ICustomer } from "@/core/data/customers";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import attendance from "@/costumComponents/classe/assiduite/attendance.vue";
import { useI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";
import AttendanceChart from "@/costumComponents/classe/assiduite/attendanceChart.vue";

interface Attendance {
  Retard: number;
  Absence: number;
}

interface ISelectedSchedule {
  seance: string;
}

interface IStudent {
  _id: string;
  BirthDate: string;
  firstName: string;
  lastName: string;
  gender: string;
}
export default defineComponent({
  name: "kt-widget-9",
  components: {
    KTDatatable,
    attendance,
    AttendanceChart,
  },
  props: {
    widgetClasses: String,
    id: String,
  },
  setup(props) {
    const { t } = useI18n();

    const route = useRoute();
    const store = useStore();
    const list = ref<IStudent[]>([]);
    const apiUrl = ref<string>(store.getters.serverConfigUrl.base_url + "/");
    const sy = window.localStorage.getItem("activeSchoolarYear");

    const targetData = ref<ISelectedSchedule>({
      seance: "",
    });
    const checkedCustomers1 = ref([]);
    const checkedCustomers2 = ref([]);

    const tableHeader = ref([
      { name: t("student.photo"), key: "photo", sortable: false },
      { name: t("student.fullname"), key: "name", sortable: true },
      { name: t("classInfo.attendance"), key: "checkbox1", sortable: true },
      {},
    ]);

    //get today's schedule
    const scheduleData = ref();
    //GET TEACHER SCHEDULES
    ApiService.setHeader();

    const query = {
      teacher: store.getters.currentUser._id,
      classeRoom: route.params.id,
      day: (moment().isoWeekday() - 1).toString(),
      status: { $ne: "inactive" },
      hidden: { $ne: true },
    };

    ApiService.post("/schedules/filter", {
      query: query,
      aggregation: [
        {
          $lookup: {
            from: "subjects",
            localField: "subject",
            foreignField: "_id",
            as: "subject",
          },
        },
        {
          $lookup: {
            from: "modules",
            localField: "module",
            foreignField: "_id",
            as: "module",
          },
        },
        {
          $lookup: {
            from: "modulespecifics",
            localField: "moduleSpecific",
            foreignField: "_id",
            as: "moduleSpecific",
          },
        },
        {
          $project: {
            _id: 1,
            time: 1,
            day: 1,
            subject: {
              $let: {
                vars: {
                  subjectVar: {
                    $arrayElemAt: ["$subject", 0],
                  },
                },
                in: {
                  name: "$$subjectVar.name",
                  _id: "$$subjectVar._id",
                  status: "$$subjectVar.status",
                },
              },
            },
            module: {
              $let: {
                vars: {
                  moduleVar: {
                    $arrayElemAt: ["$module", 0],
                  },
                },
                in: {
                  name: "$$moduleVar.name",
                  _id: "$$moduleVar._id",
                  status: "$$moduleVar.status",
                },
              },
            },
            moduleSpecific: {
              $let: {
                vars: {
                  moduleSpecificVar: {
                    $arrayElemAt: ["$moduleSpecific", 0],
                  },
                },
                in: {
                  name: "$$moduleSpecificVar.name",
                  _id: "$$moduleSpecificVar._id",
                  status: "$$moduleSpecificVar.status",
                  subjects: "$$moduleSpecificVar.subjects",
                },
              },
            },
            ends: 1,
            duration: 1,
            type: 1,
            name: 1,
            includedSubjects: 1,
          },
        },
      ],
    })
      .then(({ data }) => {
        scheduleData.value = [];
        data.map((s) => {
          if (!s.type) s.type = "S";
          if (!scheduleData.value.find((d) => d._id == s._id)) {
            if (s.type == "MP") s.subject = s.module;
            if (s.type == "MS") s.subject = s.moduleSpecific;
            scheduleData.value.push(s);
          }
        });

        scheduleData.value = scheduleData.value.filter(
          (d) => d.subject.status !== "inactive"
        );
      })
      .catch((e) => console.log(e));
    //end get schedule
    const tableData = ref<Array<ICustomer>>(customers);
    const initStudents = ref<Array<ICustomer>>([]);

    onMounted(() => {
      MenuComponent.reinitialization();
      initStudents.value.splice(0, tableData.value.length, ...tableData.value);
    });
    const search = ref<string>("");
    /*const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initStudents.value);
      if (search.value !== "") {
        let results: Array<ICustomer> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };*/

    // Added Logic
    const filtredStudents = computed(() => {
      if (search.value) {
        return list.value.filter((s: IStudent) => {
          let name: string = s.firstName + " " + s.lastName;
          return name.includes(search.value.toString());
        });
      }
      return list.value;
    });
    const loading = ref(true);
    const loadingAtt = ref(true);

    onMounted(async () => {
      //get students
      let match = {};
      match[`schoolarYearsHistory.${sy}`] = props.id;
      await ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: {
                $arrayElemAt: ["$classRoom._id", 0],
              },
              classRoomName: {
                $arrayElemAt: ["$classRoom.name", 0],
              },
              father: {
                $arrayElemAt: ["$parent.father", 0],
              },
              mother: {
                $arrayElemAt: ["$parent.mother", 0],
              },
              BirthDate: 1,
              firstName: 1,
              lastName: 1,
              gender: 1,
              photo: 1,
              adress: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          list.value = data;
        })
        .catch((e) => console.log(e))
        .finally(() => (loading.value = false));

      ApiService.post("/attendance/filter", {
        query: {
          schoolarYear: sy,
          classroom: route.params.id,
        },
      }).then(({ data }) => {
        const todayDate = moment().format("YYYY-MM-DD");
        const tomorrowDate = moment().add(1, "d").format("YYYY-MM-DD");

        attendances.value = data.filter((att) => {
          return (
            att.schedule &&
            att.date &&
            moment(att.date).isBetween(todayDate, tomorrowDate, undefined, "[]")
          );
        });

        attendance.value = data.filter((att) => {
          attendanceData.value[att.type]++;
          return att.schedule && att.schedule.classRoomId == route.params.id;
        });
        loadedAttendance.value = true;
        loadingAtt.value = false;
      });
    });

    watch(
      targetData,
      () => {
        loadingAtt.value = true;

        ApiService.post("/attendance/filter", {
          query: {
            schoolarYear: sy,
            classroom: route.params.id,
          },
        }).then(({ data }) => {
          const todayDate = moment().format("YYYY-MM-DD");
          const tomorrowDate = moment().add(1, "d").format("YYYY-MM-DD");

          attendances.value = data.filter((att) => {
            return (
              att.schedule &&
              att.date &&
              moment(att.date).isBetween(
                todayDate,
                tomorrowDate,
                undefined,
                "[]"
              )
            );
          });

          attendance.value = data.filter((att) => {
            attendanceData.value[att.type]++;
            return att.schedule && att.schedule.classRoomId == route.params.id;
          });
          loadingAtt.value = false;
        });
      },
      { deep: true }
    );

    const attendance = ref([]);
    const attendances = ref([]);
    const loadedAttendance = ref<boolean>(false);
    const attendanceData = ref<Attendance>({
      Retard: 0,
      Absence: 0,
    });

    return {
      t,
      checkedCustomers1,
      checkedCustomers2,
      targetData,
      tableData,
      tableHeader,
      search,
      filtredStudents,
      apiUrl,
      list,
      moment,
      //schedule data
      scheduleData,

      //charts
      attendance,
      loadedAttendance,
      attendanceData,
      loading,
      attendances,
      loadingAtt,
    };
  },
});
