
import ApiService from '@/core/services/ApiService'
import { watch, defineComponent, toRefs, ref } from 'vue'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import moment from 'moment'
import { useI18n } from 'vue-i18n'
import { event } from 'vue-gtag'

export default defineComponent({
  name: 'sAttendance',
  components: {},
  props: {
    schedule: String,
    id: String,
    attendances: Array,
  },
  setup(props) {
    const { t } = useI18n()

    const { schedule, id, attendances } = toRefs(props)

    const attendanceId = ref<string>('')

    const type = ref<string>('')

    const data: any = attendances.value?.find(
      (att: any) =>
        att.schedule._id == schedule.value && att.student == id.value
    )

    if (data) {
      attendanceId.value = data._id
      type.value = data.type
    }

    watch(schedule, (currentValue) => {
      const data: any = attendances.value?.find(
        (att: any) =>
          att.schedule._id == schedule.value && att.student == id.value
      )

      if (data) {
        attendanceId.value = data._id
        type.value = data.type
      } else {
        type.value = ''
        attendanceId.value = ''
      }
    })

    watch(attendances, (currentValue) => {
      const data: any = attendances.value?.find(
        (att: any) =>
          att.schedule._id == schedule.value && att.student == id.value
      )

      if (data) {
        attendanceId.value = data._id
        type.value = data.type
      } else {
        type.value = ''
        attendanceId.value = ''
      }
    })

    const changeType = function () {
      event('Save attendance', {
        event_category: 'Attendance',
        event_label: 'Classe profile',
        value: 1,
      })
      if (attendanceId.value) {
        if (type.value == '') {
          ApiService.setHeader()
          ApiService.delete('/attendance/' + attendanceId.value).then(() => {
            Swal.fire({
              text: t('attendance.updated'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok!',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        } else {
          ApiService.setHeader()
          ApiService.patch('/attendance/' + attendanceId.value, {
            data: {
              type: type.value,
            },
          }).then(() => {
            Swal.fire({
              text: t('attendance.updated'),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok!',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      } else {
        ApiService.setHeader()
        ApiService.put('/attendance', {
          data: {
            student: id.value,
            type: type.value,
            schedule: schedule.value,
            date: moment(),
          },
        }).then(({ data }) => {
          attendanceId.value = data._id
          Swal.fire({
            text: t('attendance.updated'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Ok!',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        })
      }
    }

    return {
      t,
      type,
      moment,
      changeType,
    }
  },
})
