import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f97bc7ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row my-2" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "row my-2" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = { class: "row my-2" }
const _hoisted_6 = { class: "col-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_radio_group, {
      modelValue: _ctx.type,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.type) = $event)),
      onChange: _ctx.changeType,
      fill: "#999999"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_radio, {
              class: "green",
              label: ''
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('attendance.present')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_radio, {
              class: "orange",
              label: 'Retard'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('attendance.delay')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_radio, {
              class: "red",
              label: 'Absence'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('attendance.absent')), 1)
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "onChange"])
  ]))
}